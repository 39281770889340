export const API_URL = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '');
export const API_URL_SITES_SERVICE = `${API_URL}${process.env.REACT_APP_SITES_SERVICE_SUFFIX}`.replace(
  /\/$/,
  ''
);
export const API_URL_LEDGER = `${API_URL}${process.env.REACT_APP_LEDGER_SUFFIX}`.replace(
  /\/$/,
  ''
);
export const API_URL_IDENTIFIER = `${API_URL}${process.env.REACT_APP_IDENTIFIER_SUFFIX}`.replace(
  /\/$/,
  ''
);
export const API_URL_UMS = `${API_URL}${process.env.REACT_APP_UMS_SUFFIX}`.replace(
  /\/$/,
  ''
);

export const API_URL_CLAIM_MANAGEMENT = `${API_URL}${process.env.REACT_APP_CLAIM_MANAGEMENT_SUFFIX}`.replace(
  /\/$/,
  ''
);

export const LOCAL_TENANT = process.env.REACT_APP_LOCAL_TENANT;

export const ESTONIAN_ADDRESS_LOOKUP_URL =
  process.env.REACT_APP_ESTONIAN_ADDRESS_LOOKUP_URL;

export const UK_ADDRESS_LOOKUP_URL =
  process.env.REACT_APP_UK_ADDRESS_LOOKUP_URL;
export const UK_ADDRESS_LOOKUP_API_KEY =
  process.env.REACT_APP_UK_ADDRESS_LOOKUP_API_KEY;

export const GA_MEASUREMENT_ID =
  process.env.REACT_APP_GA_MEASUREMENT_ID ?? null;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? null;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV ?? 'local';
export const SENTRY_RATE = process.env.REACT_APP_SENTRY_RATE ?? 0.1;

export const I18N_PROJECT_ID = process.env.REACT_APP_I18N_PROJECT_ID;
export const I18N_API_KEY = process.env.REACT_APP_I18N_API_KEY;
export const I18N_LNG = process.env.REACT_APP_I18N_LNG;

export const IS_DEV_MODE = !!process.env.REACT_APP_DEV_MODE;

export const GOOGLE_MAP_LOOKUP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_LOOKUP_API_KEY;

export const INTERCOM_SECRET_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY;

export const REPLIT_APP_URL = process.env.REACT_APP_REPLIT_APP_URL;

export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const OPENAI_ORG_ID = process.env.REACT_APP_OPENAI_ORG_ID;

export const VOICEFLOW_PROJECT_ID = process.env.REACT_APP_VOICEFLOW_PROJECT_ID;
